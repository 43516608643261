var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "loading-container" } }, [
    _c(
      "div",
      { attrs: { id: "loading-banner" } },
      [_c("spin-loader", { attrs: { "is-large": "" } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }